<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo de Carta - Editar</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" @submit.prevent="Validate">

              <b-tabs content-class="mt-3">
                <b-tab title="Carta" active>
                  <b-row>
                    <b-col md="3">
                      <div class="w-100 text-center">
                        <img class="img-fluid" :src="previewImage">
                      </div>
                      <b-col md="12">
                        <b-form-group label="Foto: ">
                          <b-form-file @change="PreviewImage" accept="image/*" v-model="photo" placeholder="Seleccione un foto..." drop-placeholder="Suelta la imagen aquí..."></b-form-file>
                          <small class="form-text text-center text-secondary">Tamaño recomendado 400px x 500px</small>
                        </b-form-group>
                      </b-col>
                    </b-col>

                    <b-col md="9">
                      <b-row>
                          <b-col md="2">
                            <b-form-group label="Categoria :">
                              <b-form-select v-model="letter.id_category_letter" :options="categories"></b-form-select>
                              <small v-if="errors.id_category_letter" class="form-text text-danger">Seleccione una categoria</small>
                            </b-form-group>
                          </b-col>

                          <b-col md="2">
                            <b-form-group label="Codigo:">
                              <b-form-input readonly type="text" class="text-center" v-model="letter.code"></b-form-input>
                              <small v-if="errors.code" class="form-text text-danger" >Ingrese un código</small>
                            </b-form-group>
                          </b-col>

                          <b-col md="5">
                            <b-form-group label="Nombre:">
                              <b-form-input  type="text" v-model="letter.name" ></b-form-input>
                              <small v-if="errors.name" class="form-text text-danger">Ingrese un nombre</small>
                            </b-form-group>
                          </b-col>

                          <b-col md="3">
                            <b-form-group label="Nombre corto:">
                              <b-form-input  type="text" v-model="letter.short_name" ></b-form-input>
                              <small v-if="errors.short_name" class="form-text text-danger">Ingrese un nombre</small>
                            </b-form-group>
                          </b-col>

                            <b-col md="12">
                            <b-form-group label="Descripción:">
                              <b-form-textarea ref="description"  v-model="letter.description"></b-form-textarea>
                            </b-form-group>
                          </b-col>

                          <b-col md="3">
                            <b-form-group label="Presentación :">
                              <b-form-input  type="text" ref="presentation" v-model="letter.presentation" ></b-form-input>
                              <small v-if="errors.presentation" class="form-text text-danger">Ingrese una presentacion</small>
                            </b-form-group>
                          </b-col>

                          <b-col md="3">
                            <b-form-group label="Unidad de Medida :">
                              <b-form-select v-model="letter.unit_measure" :options="unit_measure_letter"></b-form-select>
                            </b-form-group>
                          </b-col>

                          <b-col md="3">
                            <b-form-group label="Precio Minimo:">
                              <b-form-input type="text" class="text-right" v-model="letter.minimal_price"></b-form-input>
                            </b-form-group>
                          </b-col>

                          <b-col md="3">
                            <b-form-group label="Precio Venta:">
                              <b-form-input type="text" class="text-right" v-model="letter.sale_price"></b-form-input>
                            </b-form-group>
                          </b-col>


                          <b-col md="3">
                            <b-form-group label="Codigo de Barras:">
                              <b-form-input type="text" v-model="letter.barcode"></b-form-input>
                            </b-form-group>
                          </b-col>

                          <b-col md="3">
                            <b-form-group label="Tipo de afectación :">
                              <b-form-select v-model="letter.igv" :options="igv"></b-form-select>
                            </b-form-group>
                          </b-col>

           

                          <b-col md="3">
                            <b-form-group label="Plato? :">
                              <b-form-select v-model="letter.stock" :options="stock"></b-form-select>
                            </b-form-group>
                          </b-col>

                          <b-col md="3">
                            <b-form-group label="Estado :">
                              <b-form-select v-model="letter.state" :options="state"></b-form-select>
                            </b-form-group>
                          </b-col>

                          <b-col md="5"></b-col>
                          <b-col md="2">
                            <b-button type="submit" class="form-control"  variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button >
                          </b-col>


                      </b-row> 
                    </b-col>
                    
                  </b-row>
                </b-tab>
                <b-tab title="Productos">
                  <b-row>
                    <b-col md="9">
                        <b-form-group label="Producto :">
                        <v-select placeholder="Seleccione un producto" class="w-100" :filterable="false" label="name" v-model="product.mproduct" @search="SearchProduct" :options="product.mproducts"></v-select>
                        <small v-if="errors.product" class="form-text text-danger">Seleccione un producto</small>
                        </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label="Cantidad:">
                        <b-form-input  type="number" step="any" v-model="product.quantity" class="text-right" ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col md="1">
                      <b-form-group label=".">
                        <b-button type="button" @click="AddProduct" variant="primary" class="form-control"><i class="fas fa-plus-square"></i></b-button>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <div class="table-responsive mt-3">
                    <table class="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th width="5%" class="text-center">#</th>
                          <th width="80%" class="text-center">Nombre</th>
                          <th width="10%" class="text-center">Cantidad</th>
                          <th width="5%" class="text-center">Acciones</th>
                        </tr>
                      </thead>
                      <tbody v-for="(item, it) in product.products" :key="it">
                        <tr>
                          <td class="text-center">{{ it + 1 }}</td>
                          <td class="text-left"> {{ item.name }}</td>
                          <td class="text-center">
                            <b-form-input  type="number" step="any" v-model="item.quantity" class="text-right form-control" ></b-form-input>
                          </td>
                          <td class="text-center">
                            <b-button  type="button" @click="DeleteProduct(it)" variant="danger" ><i class="fas fa-trash-alt"></i></b-button>  
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <b-row>
                    <b-col md="5"></b-col>
                    <b-col md="2">
                      <b-button type="submit" class="form-control"  variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button >
                    </b-col>
                  </b-row>

                </b-tab>
                <b-tab title="Observaciones">
                  <b-row>
                    <b-col md="11">
                        <b-form-group label="Ingrediente :">
                          <b-form-input  type="text" v-model="ingredient.name" ></b-form-input>
                          <small v-if="errors.product" class="form-text text-danger">Seleccione un producto</small>
                        </b-form-group>
                    </b-col>
                    <b-col md="1">
                      <b-form-group label=".">
                        <b-button type="button" @click="AddIngredient" variant="primary" class="form-control"><i class="fas fa-plus-square"></i></b-button>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <div class="table-responsive mt-3">
                    <table class="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th width="5%" class="text-center">#</th>
                          <th width="90%" class="text-center">Nombre</th>
                          <th width="5%" class="text-center">Acciones</th>
                        </tr>
                      </thead>
                      <tbody v-for="(item, it) in ingredient.ingredients" :key="it">
                        <tr>
                          <td class="text-center">{{ it + 1 }}</td>
                          <td class="text-left">
                            <b-form-input  type="text" v-model="item.name" class="text-left form-control" ></b-form-input>
                          </td>
                          <td class="text-center">
                            <b-button  type="button" @click="DeleteIngredient(it)" variant="danger" ><i class="fas fa-trash-alt"></i></b-button>  
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <b-row>
                    <b-col md="5"></b-col>
                    <b-col md="2">
                      <b-button type="submit" class="form-control"  variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button >
                    </b-col>
                  </b-row>
                 


                </b-tab>
              </b-tabs>

              
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import LoadingComponent from './../pages/Loading'
import CodeToName from "@/assets/js/CodeToName";
export default {
  name: "UsuarioAdd",
  props: ["id_letter"],
  components:{
      vSelect,
      Keypress: () => import('vue-keypress'),
    LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: "Letter",
      role: 3,
      letter: {
        id_category_letter: "",
        code: "",
        name: "",
        short_name: "",
        barcode: "",
        description: "",
        presentation: "",
        existence_type: "01",
        unit_measure: "NIU",
        igv: "10",
        internal_product: 0,
        photo: "",
        unspsc_code:'23251602',
        minimal_price: 0.0,
        sale_price: 0.0,
        stock: 1,
        state: 1,
      },

      product:{
        mproduct:null,
        mproducts:[],
        quantity:'1.00',
        products:[],
      },
      ingredient:{
        name:'',
        unit_measure:'NIU',
        quantity:'1.00',
        ingredients:[],
      },
      previewImage: 'https://loisjeans.id/skin/frontend/base/default/images/catalog/product/placeholder/image.jpg',
      categories: [],
      photo: null,
      state:[
        {value:1,text:'Activo'},
        {value:0,text:'Inactivo'},
      ],
      igv:[
        {value:10,text:'Gravado'},
        {value:20,text:'Exonerado'},
        {value:30,text:'Inafecto'},
      ],
      unit_measure:[
        {value:'4A',text:'BOBINAS'},
        {value:'BJ',text:'BALDE'},
        {value:'BLL',text:'BARRILES'},
        {value:'BG',text:'BOLSA'},
        {value:'BO',text:'BOTELLAS'},
        {value:'BX',text:'CAJA'},
        {value:'CMK',text:'CENTIMETRO CUADRADO'},
        {value:'CMQ',text:'CENTIMETRO CUBICO'},
        {value:'CMT',text:'CENTIMETRO LINEAL'},
        {value:'CEN',text:'CIENTO DE UNIDADES'},
        {value:'CY',text:'CILINDRO'},
        {value:'DZN',text:'DOCENA'},
        {value:'DZP',text:'DOCENA POR 10**6'},
        {value:'BE',text:'FARDO'},
        {value:'GLI',text:'GALON INGLES (4,545956L)'},
        {value:'GRM',text:'GRAMO'},
        {value:'KGM',text:'KILOGRAMO'},
        {value:'CA',text:'LATAS'},
        {value:'LBR',text:'LIBRAS'},
        {value:'LTR',text:'LITRO'},
        {value:'MTR',text:'METRO'},
        {value:'MGM',text:'MILIGRAMOS'},
        {value:'MIL',text:'MILLARES'},
        {value:'UM',text:'MILLON DE UNIDADES'},
        {value:'ONZ',text:'ONZAS'},
        {value:'PF',text:'PALETAS'},
        {value:'PK',text:'PAQUETE'},
        {value:'GRM',text:'GRAMO'},
        {value:'PR',text:'PAR'},
        {value:'C62',text:'PIEZAS'},
        {value:'NIU',text:'UNIDAD (BIENES) '},
        {value:'ZZ',text:'UNIDAD (SERVICIOS)'},
        {value:'GRM',text:'US GALON (3,7843 L)'},
        {value:'GLL',text:'GRAMO'},
      ],
      unit_measure_letter:[
        {value:'NIU',text:'UNIDAD (BIENES) '},
        {value:'ZZ',text:'UNIDAD (SERVICIOS)'},
      ],
      stock :[
        {value:0,text:'NO'},
        {value:1,text:'SI'},
      ],                                               
      internal_product :[
        {value:0,text:'NO'},
        {value:1,text:'SI'},
      ],
      errors: {
        id_category_letter: false,
        code: false,
        name: false,
        state: false,
      },
      validate: false,
    };
  },
  mounted() {
    this.ListCategories();
    this.ViewLetter();
  },
  methods: {
    PreviewImage,
    ListCategories,
    ViewLetter,
    EditLetter,
    Validate,
    NameUnitMeasure,

    SearchProduct,
    AddProduct,
    DeleteProduct,

    AddIngredient,
    DeleteIngredient
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};


function NameUnitMeasure(code) {
    return CodeToName.NameUnitMeasure(code);
}

//PRODUCTS
function SearchProduct(search, loading) {
  
    let me = this;
    let url = this.url_base + "product/search-select";
    let data = {
      search : search,
      type_stock:'1'
    }
    if (search !== "") {
      loading(true);
      axios({
        method: "POST",
        url: url,
        data: data,
        headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: me.role},
      }).then(function (response) {
            me.product.mproducts = response.data.result;
            loading(false);
      })
    }
    
}

function AddProduct() {
  let quantity = this.product.quantity;
  let product = this.product.mproduct;
  if (product == null) {
    Swal.fire({ icon: 'warning', text: 'Seleccione un producto', timer: 2000,}); return false;
  }
  if (quantity.length == 0) {
    Swal.fire({ icon: 'warning', text: 'Ingrese una cantidad', timer: 2000,}); return false;
  }
  const index = this.product.products.map(item => item.id_product).indexOf(product.id);
  if (index < 0) {
    this.product.products.push({
      id_product: product.id,
      name: product.name,
      quantity: parseFloat(quantity).toFixed(2),
    });
    this.product.mproduct = null;
    this.product.quantity = '1.00';
  }
}

function DeleteProduct(index) {
  this.product.products.splice(index, 1);
}

//INGREDIENTES
function AddIngredient() {
  let name = this.ingredient.name;
  let unit_measure = 'NIU';
  let quantity = 1;
  if (name.length == 0) {
    Swal.fire({ icon: 'warning', text: 'Ingrese un nombre', timer: 2000,}); return false;
  }
  if (unit_measure.length == 0) {
    Swal.fire({ icon: 'warning', text: 'Ingrese una unidad de medida', timer: 2000,}); return false;
  }
  if (quantity.length == 0) {
    Swal.fire({ icon: 'warning', text: 'Ingrese una cantidad', timer: 2000,}); return false;
  }
  
  this.ingredient.ingredients.push({
    name: name,
    unit_measure: unit_measure,
    quantity: parseFloat(quantity).toFixed(2),
  });
  this.ingredient.name = '';
  this.ingredient.unit_measure = 'NIU';
  this.ingredient.quantity = '1.00';
  
}

function DeleteIngredient(index) {
  this.product.products.splice(index, 1);
}


//LETTER
function PreviewImage(event) {
  var input = event.target;
  if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = (e) => {
          this.previewImage = e.target.result;
      }
      reader.readAsDataURL(input.files[0]);
  }
  this.letter.photo = input.files[0];
}

function ViewLetter() {

  let id_letter = je.decrypt(this.id_letter);
  let me = this;
  let url = this.url_base + "letter/view/"+id_letter;
  axios({
    method: "GET",
    url: url,
    headers: {token: this.token,module: this.module,role: this.role},
  })
  .then(function (response) {
    if (response.data.status == 200) {
        me.letter.id_letter = response.data.result.letter.id_letter;
        me.letter.id_category_letter = response.data.result.letter.id_category_letter;
        me.letter.code = response.data.result.letter.code;
        me.letter.name = response.data.result.letter.name;
        me.letter.short_name = response.data.result.letter.short_name;
        me.letter.barcode = response.data.result.letter.barcode;
        me.letter.description = response.data.result.letter.description;
        me.letter.presentation = response.data.result.letter.presentation;
        me.letter.existence_type = response.data.result.letter.existence_type;
        me.letter.unit_measure = response.data.result.letter.unit_measure;
        me.letter.igv = response.data.result.letter.igv;
        me.letter.internal_product = response.data.result.letter.internal_product;
        me.letter.photo = response.data.result.letter.photo;
        me.letter.unspsc_code = response.data.result.letter.unspsc_code;
        me.letter.minimal_price = response.data.result.letter.minimal_price;
        me.letter.sale_price = response.data.result.letter.sale_price;
        me.letter.stock = response.data.result.letter.stock;
        me.letter.state = response.data.result.letter.state;
        me.previewImage = me.url_base + response.data.result.letter.photo;
        for (let index = 0; index < response.data.result.products.length; index++) {
          const element = response.data.result.products[index];
          me.product.products.push({id_product: element.id_product,name: element.code + " | " +element.name + (element.presentation.length == 0 ? "":" - "+element.presentation) + " | " + me.NameUnitMeasure(element.unit_measure),quantity:element.quantity})
        }
        me.ingredient.ingredients = response.data.result.ingredients;
    } 
  })
}

function ListCategories() {
  let me = this;
  let url = this.url_base + "category-letter/list-active";
  axios({
    method: "GET",
    url: url,
     headers: { token: this.token, module: this.module, role: this.role},
  })
  .then(function (response) {
    me.categories = [{value:'',text:'Seleccione una categoria'}];
    if (response.data.status == 200) {
      for (let index = 0; index < response.data.result.length; index++) {
        const element = response.data.result[index];
          me.categories.push({value:element.id_category_letter,text:element.name});
      }
    } else {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    }
  })
  .catch((error) => {
    Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
  });
}

function EditLetter() {

  let me = this;
  let url = me.url_base + "letter/edit";
  let data = new FormData();
  data.append("id_letter", this.letter.id_letter);
  data.append("id_category_letter", this.letter.id_category_letter);
  data.append("code", this.letter.code);
  data.append("name", this.letter.name);
  data.append("short_name", this.letter.short_name);
  data.append("barcode", this.letter.barcode);
  data.append("description", this.letter.description);
  data.append("presentation", this.letter.presentation);
  data.append("existence_type", this.letter.existence_type);
  data.append("unit_measure", this.letter.unit_measure);
  data.append("igv", this.letter.igv);
  data.append("internal_product", this.letter.internal_product);
  data.append("photo", this.letter.photo);
  data.append("unspsc_code", this.letter.unspsc_code);
  data.append("minimal_price", this.letter.minimal_price);
  data.append("sale_price", this.letter.sale_price);
  data.append("stock", this.letter.stock);
  data.append("state", this.letter.state);

  data.append("products", JSON.stringify(this.product.products));
  data.append("ingredients", JSON.stringify(this.ingredient.ingredients));

  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: me.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        Swal.fire({ icon: 'success', text: response.data.message , timer: 3000,})
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}

function Validate() {

  this.errors.id_category_letter = this.letter.id_category_letter.length == 0 ? true : false;
  this.errors.name = this.letter.name.length == 0 ? true : false;
  this.errors.short_name = this.letter.short_name.length == 0 ? true : false;
  this.errors.code = this.letter.code.length == 0 ? true : false;
  this.errors.minimal_price = this.letter.minimal_price.length == 0 ? true : false;
  this.errors.sale_price = this.letter.sale_price.length == 0 ? true : false;
  this.errors.state = this.letter.state.length == 0 ? true : false;

  if (this.errors.id_category_letter) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.code) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.name) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.short_name) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.minimal_price) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.sale_price) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.state) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }

  let me = this;

  if (!this.validate) {
    Swal.fire({
      title: "Esta seguro de modificar la carta ?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Estoy de acuerdo!",
    }).then((result) => {
      if (result.value) {
        this.EditLetter();
      }
    });

  }

}
</script>
